//
// Rating
//

/* Importing Rating bar SCSS files */
@import '~ngx-bar-rating/themes/br-default-theme';
@import '~ngx-bar-rating/themes/br-bootstrap-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-o-theme';
@import '~ngx-bar-rating/themes/br-horizontal-theme';
@import '~ngx-bar-rating/themes/br-stars-theme';
@import '~ngx-bar-rating/themes/br-movie-theme';
@import '~ngx-bar-rating/themes/br-vertical-theme';
@import '~ngx-bar-rating/themes/br-square-theme';
@import '~ngx-bar-rating/themes/br-horizontal-theme';

.rating-symbol-background,
.rating-symbol-foreground {
  font-size: 24px;
}

.rating-symbol-foreground {
  top: 0px;
}

.br-horizontal{
  justify-content: start !important;
}

.br-vertical {
  display: block !important;
}