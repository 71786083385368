/*
Template Name: Minible - Admin & Dashboard Template
Author: Themesbrand
Version: 2.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";