//
// Datepicker
//

@import '~flatpickr/dist/flatpickr.css';

ngb-datepicker {
  background-color: $dropdown-bg;
  position: absolute;
  z-index: $zindex-dropdown;
}

ngb-datepicker-navigation-select>.custom-select {
  margin: 0 5px;
}

.ngb-dp-day,
.ngb-dp-today {
  outline: none !important;

  .btn-light,
  .btn-primary {
    box-shadow: none !important;
    outline: none !important;
    border-radius: 50%;
  }
}

.ngb-dp-weekday {
  font-style: normal !important;
}

.ngb-tp {
  box-shadow: $box-shadow;
  background-color: $dropdown-bg;
  margin-top: 5px;
  float: left;
  min-width: 10rem;
  padding: 0.75rem;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: $dropdown-color;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: $dropdown-border-radius;
  position: absolute;
  z-index: $zindex-dropdown;
}

// flatpicker
.input-daterange {
  .flatpickr-input {
    padding: 0.47rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;

    &:focus {
      outline: none !important;
    }
  }
}

.ng2-flatpickr-input {
  width: 100%;
  border: none;
  outline: none;
}

// Date Range
.custom-day {
  line-height: 2rem;
  border-radius: 50%;
  padding: 0 !important;
  outline: none !important;

  &:hover {
    background-color: $primary !important;
  }

  &.range {
    background-color: $primary !important;
  }

  &.range.faded {
    background-color: rgba($primary, 0.25) !important;
    color: $primary !important;
  }
}


// flatpickr

.flatpickr-input {
  &[readonly] {
    background-color: $input-bg;
  }
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: $primary;
}

span.flatpickr-weekday {
  color: $white;
  font-weight: $font-weight-medium;
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-months {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.flatpickr-am-pm,
.numInput,
.numInputWrapper {

  &:focus,
  &:hover {
    background-color: transparent !important;
  }
}

.flatpickr-weekdays {
  height: 36px !important;
  border-bottom: 1px solid $border-color;
}

.flatpickr-day {
  color: $dark !important;

  &:hover,
  &:focus {
    background-color: rgba($light, 0.7) !important;
  }

  &.today {
    border-color: $primary !important;
    background-color: rgba($primary, 0.1);

    &:hover,
    &:focus {
      color: $dark !important;
      background-color: rgba($primary, 0.15) !important;
    }
  }

  &.selected {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    color: rgba($dark, 0.3) !important;
  }

  &.inRange,
  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    background-color: $light;
    border-color: $light;
  }

  &.inRange {
    box-shadow: -5px 0 0 $light, 5px 0 0 $light;
  }
}

.flatpickr-months {

  .flatpickr-prev-month,
  .flatpickr-next-month,
  .flatpickr-month {
    color: rgba($white, 0.9) !important;
    fill: rgba($white, 0.9) !important;
  }
}

.flatpickr-monthDropdown-month {
  color: rgba($black, 0.8);
}

.flatpickr-current-month {
  input.cur-year {

    &[disabled],
    &[disabled]:hover {
      color: rgba($white, 0.9) !important;
    }
  }
}

.flatpickr-time {

  input,
  .flatpickr-time-separator,
  .flatpickr-am-pm {
    color: $dark !important;
  }
}

.flatpickr-calendar {
  background-color: $dropdown-bg !important;
  box-shadow: 1px 0 0 $border-color, -1px 0 0 $border-color, 0 1px 0 $border-color, 0 -1px 0 $border-color,
    0 3px 13px rgba(0, 0, 0, 0.08) !important;

  &.hasTime {
    .flatpickr-time {
      border-top: 1px solid $border-color !important;
    }
  }
}

.flatpickr-months {
  border-radius: 5px 5px 0px 0px;

  .flatpickr-prev-month,
  .flatpickr-next-month {
    &:hover {
      svg {
        fill: rgba($white, 0.9) !important;
      }
    }
  }
}
