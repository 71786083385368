/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';



  