
//
// colorpicker.scss
//

.colorpicker {
  background: $dropdown-bg;
  border: 1px solid $dropdown-border-color;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #eff2f7;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}